var sliderFeatures = tns({
	"container": ".slider-features",
	"controlsContainer": ".slider-features-controls",
	"preventScrollOnTouch": "force",
	"controlsPosition": "bottom",
	"controls": false,
	"navPosition": "bottom",
	"mouseDrag": true,
	"speed": 400,
	"fixedWidth": 288,
	"gutter": 32,
	"responsive": {
		"576": {
			"center": true
		},
		"768": {
			"items": 2,
			"slideBy": 2,
			"controls": true,
			"center": false
		},
		"1280": {
			"fixedWidth": 384
		},
		"1366": {
			"fixedWidth": 412
		},
		"1600": {
			"fixedWidth": 432
		}
	}
});
